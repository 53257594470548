@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap");

@layer base {
  body {
    @apply font-sans;
  }
}

.thing-enter {
  transform: translateY(100px);
  opacity: 0;
}

.thing-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: opacity 500ms, transform 500ms;
}

@keyframes random-shake {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(var(--x), var(--y));
  }
}

.hover-wave span {
  display: inline-block;
  transition: transform 0.1s ease-in-out;
}

.animate-shake span {
  animation: random-shake 0.35s ease;
}
